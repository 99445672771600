import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from '../../utils/typography'
import styled from "@emotion/styled";
import {
    pintainer
} from '../../components/layout.css'

import body from '../../images/draw-body-dark.png'
import hand from '../../images/draw-hand-dark-square.png'
import toscana from '../../images/draw-toscana-dark.jpg'
import rosenheim from '../../images/draw-rosenheim-dark.png'
import bridge from '../../images/draw-bridge-dark.png'
import ekb from '../../images/draw-ekb-dark.png'
import sh from '../../images/draw-buddah-dark.png'
import bike from '../../images/bike-dark.jpg'



const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${rhythm(25)})  {
    flex-direction: column;
    & > * + * {
      margin-top: ${rhythm(0.5)}; 
    }
    div {
      align-items: center !important;
    }
  }
  
`



const ThirdPage = () => (
  <Layout>
      <script data-goatcounter="https://metrics.goatcounter.com/count"
              async src="//gc.zgo.at/count.js"></script>
    <SEO title="Pavli Pinterest.." />
      <div style={{display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          padding: `10px` }}>
          <img style={{width: `450px`, height: `100%`, margin: `20px`}} src={toscana} alt={''}/>
          <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={rosenheim} alt={''}/>
          <img style={{width: `700px`, height: `auto`, margin: `20px`}} src={bridge} alt={''}/>
          <img style={{width: `450px`, height: `auto`, margin: `20px`}} src={hand} alt={''}/>
          <img style={{width: `450px`, height: `auto`, margin: `20px`}} src={sh} alt={''}/>
          <img style={{width: `700px`, height: `auto`, margin: `20px`}} src={toscana} alt={''}/>
          <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={hand} alt={''}/>
          <img style={{width: `700px`, height: `auto`, margin: `20px`}} src={sh} alt={''}/>
          <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={body} alt={''}/>
          <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={ekb} alt={''}/>
          <img style={{width: `auto`, height: `auto`, margin: `20px`}} src={bike} alt={''}/>

      {/*    place for more*/}
      </div>
      <div style={{textAlign:`center`}}>
        <small id="citation">
          Created with care just for fun
        </small>
        </div>
  </Layout>
)

export default ThirdPage
